var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isMobile
        ? [
            _c(
              "div",
              { staticClass: "manager-statistic" },
              [
                !_vm.isExporter
                  ? [
                      _vm._l(_vm.getStatisticList, function (item) {
                        return [
                          _vm.isShowItem(item.value)
                            ? _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "manager-statistic__item",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "manager-statistic__title" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.capitalize(item.title)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "manager-statistic__value" },
                                    [_vm._v("- " + _vm._s(item.value))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ]
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "manager-statistic__wrap-btn-auto" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Заполните параметр “Дата с“",
                          disabled: _vm.enableExcel,
                          placement: "top",
                        },
                      },
                      [
                        _vm.showExcelButton
                          ? _c(
                              "IqButton",
                              {
                                staticStyle: { width: "auto" },
                                attrs: {
                                  size: "mini",
                                  color: "primary",
                                  disabled: !_vm.enableExcel,
                                },
                                on: { onClick: _vm.handleExcelReport },
                              },
                              [_vm._v(" Выгрузить отчет в Excel ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.showCreateButton && _vm.canCreateManualsTs
                      ? _c(
                          "IqButton",
                          {
                            staticStyle: { width: "auto" },
                            attrs: {
                              size: "mini",
                              icon: "TruckIcon",
                              "icon-height": "28px",
                              "icon-width": "28px",
                            },
                            on: { onClick: _vm.handleAddAuto },
                          },
                          [_vm._v(" Добавить авто ")]
                        )
                      : _vm._e(),
                    _vm.showDeleteButton
                      ? _c(
                          "IqButton",
                          {
                            staticStyle: { width: "auto" },
                            attrs: {
                              size: "mini",
                              disabled: !_vm.enableDeleting,
                            },
                            on: { onClick: _vm.handleMassDelete },
                          },
                          [_vm._v(" Удалить выбранные ТС ")]
                        )
                      : _vm._e(),
                    _vm.showChangeTableButton
                      ? _c(
                          "IqButton",
                          {
                            staticStyle: { width: "auto" },
                            attrs: {
                              size: "mini",
                              icon: "IconSettings",
                              "icon-height": "28px",
                              "icon-width": "28px",
                            },
                            on: { onClick: _vm.handleChangeTable },
                          },
                          [_vm._v(" Изменить таблицу ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
            !_vm.isExporter
              ? _c("div", { staticClass: "manager-statistic mod-limit" }, [
                  _c("div", [_vm._v(_vm._s(_vm.manualsInfoText.text))]),
                  _vm.manualsInfoText.quantity
                    ? _c("div", { staticClass: "manager-statistic__value" }, [
                        _vm._v(
                          " " + _vm._s(_vm.manualsInfoText.quantity) + " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        : [
            !_vm.isExporter
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "flex-wrap": "wrap",
                    },
                  },
                  [
                    _vm._l(_vm.getStatisticList, function (item) {
                      return [
                        _vm.isShowItem(item.value)
                          ? _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "manager-statistic__item",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "manager-statistic__title" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.capitalize(item.title)) +
                                        ": "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "manager-statistic__value" },
                                  [_vm._v(_vm._s(item.value))]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _c("div", { staticClass: "manager-statistic__item" }, [
                      _c("div", { staticClass: "manager-statistic__title" }, [
                        _vm._v(_vm._s(_vm.manualsInfoText.text)),
                      ]),
                      _vm.manualsInfoText.quantity
                        ? _c(
                            "div",
                            { staticClass: "manager-statistic__value" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.manualsInfoText.quantity) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }